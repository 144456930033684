/* eslint-disable max-len */
export default {
  accessToken:
    'pk.eyJ1IjoiZ3VsYWdtYXAiLCJhIjoiY2lxa3VtaWtyMDAyZGhzbWI1aDQ3NGhtayJ9.D2IEMpF7p8yNtpY_2HUQlw',
  mapStyle: 'mapbox://styles/gulagmap/cjcvqetwy0ppv2so8nc0e4db7',
  maxZoom: 8,
  minZoom: 2,
  pitchWithRotate: false,
  dragRotate: false,
  viewportChangeMethod: 'easeTo'
};
