import styled from 'styled-components';

export default styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  padding-bottom: 15px;
  border-bottom: 1px solid rgba(226, 243, 227, 0.1);
  margin-bottom: 15px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s;

  &:hover {
    opacity: 1;
  }
`;
