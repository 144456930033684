import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'intl/helper';

import FullScreenCard from '../FullScreenCard/FullScreenCard';

import Period from './Period';
import Year from './Year';
import PeriodTitle from './PeriodTitle';
import Description from './Description';
import PeriodInfo from './PeriodInfo';
import Timeline from './Timeline';


class Chronology extends PureComponent {
  render() {
    const { pushToRoot, periods, lang } = this.props;
    return (
      <FullScreenCard onClick={pushToRoot} title={t('aside.gulagChronology')}>
        {periods.map((period, i) => (
          <Period key={period.get('id')} className='to-show'>
            <Year>{period.get('year')}</Year>
            <Timeline hideLine={periods.toJS().length === i + 1} />
            <PeriodInfo>
              <PeriodTitle>{period.getIn(['title', lang])}</PeriodTitle>
              <Description>{period.getIn(['description', lang])}</Description>
            </PeriodInfo>
          </Period>
        ))}
      </FullScreenCard>
    );
  }
}

Chronology.propTypes = {
  pushToRoot: PropTypes.func.isRequired,
  periods: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired
};

export default Chronology;
