import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 1023px) {
    overflow-x: scroll;
    padding-left: calc(50vw - 22px);
    padding-right: calc(50vw - 22px);
    padding-bottom: 105px;
    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(22px);
    scroll-snap-type: x mandatory;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;