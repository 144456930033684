import React from 'react';
import styled from 'styled-components';
import campColors from 'config/campsColors';
import dropdownIcon from 'assets/icons/campdropdown.svg';
import mobileDropdownIcon from 'assets/icons/campdropdownmobile.svg';
import searchIcon from 'assets/icons/search.svg';
import SearchButton from './SearchButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  position: fixed;
  right: 20px;
  top: 20px;
`;

const CampDropdown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  gap: 10px;
  width: 388px;

  background: #171b20;
  border-radius: 16px;

  @media (max-width: 768px) {
    width: 46px;
    height: 46px;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;



const CampDropdownIcon = () => (
  <IconWrapper mobile={false} margin='9px'>
    <img src={dropdownIcon} alt='Dropdown' />
  </IconWrapper>
);

const CampDropdownSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  height: 14px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CampDropdownSelectionBadge = ({ color }) => (
  <svg
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='4' cy='4' r='4' fill={color} />
  </svg>
);

const CampDropdownSelectionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  display: flex;
  align-items: center;

  color: #cfcfd0;

  -webkit-line-clamp: 1;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CampDropdownMobileIcon = () => (
  <IconWrapper mobile margin='8px'>
    <img src={mobileDropdownIcon} alt='Dropdown' />
  </IconWrapper>
);

const IconWrapper = styled.div`
  margin-top: ${({ margin }) => margin || '0'};
  margin-bottom: ${({ margin }) => margin || '0'};
  vertical-align: middle;
  @media (${({ mobile }) => mobile ? 'min-width: 768px' : 'max-width: 767px'}) {
    display: none;
  }
`;

export default function Panel({
  campTypeFilters,
  toggleCampFilters,
  typesInfo,
  locale,
  openSearch
}) {
  return (
    <Container>
      <SearchButton onClick={openSearch}>
        <img src={searchIcon} alt='Search' />
      </SearchButton>

      <CampDropdown onClick={toggleCampFilters}>
        <CampDropdownSelection>
          <CampDropdownSelectionBadge
            color={`rgb(${campColors[campTypeFilters]})`}
          />
          <CampDropdownSelectionText>
            {typesInfo
              .toArray()
              .find(type => type.get('id').toString() === campTypeFilters)
              ?.getIn(['title', locale])}
          </CampDropdownSelectionText>
        </CampDropdownSelection>
        <CampDropdownMobileIcon />
        <CampDropdownIcon />
      </CampDropdown>
    </Container>
  );
}
