import React from 'react';
import Description from '../styled/Description';
import Link from '../styled/Link';

export default function AcknowledgementsEn() {
  return (
    <Description>
      <div>
        For helping us find information and providing archival documents and
        photographs, we express our gratitude to:
      </div>
      <div>State Archive of the Russian Federation</div>
      <div>
        Memorial, International Historical and Educational, Charitable and Human
        Rights Society
      </div>
      <div>
        Yury Dmitiyev, Head of the Karelian branch of International Memoria
      </div>
      <div>
        Viktor Paaso, Karelian Republican Public Institution “Memorial”
        Scientific and Research Center
      </div>
      <div>
        Mikhail Rogachyov, Komi Republican Charitable Public Foundation for
        Victims of Political Repression “Pokayaniye”
      </div>
      <div>
        Aleksey Zakharchenko, Samara Branch of Moscow City Pedagogical
        University
      </div>
      <div>Svetlana Berezovskaya, Seversk City Museum</div>
      <div>Vasily Khanevich, The NKVD Remand Prison Memorial Museum</div>
      <div>
        Irina Vitman, Museum “The City in the North of Russia” (Vorkuta)
      </div>
      <div>Irina Ustin, Kem Museum of Regional Studies “Pomorye”</div>
      <div>
        Nadezhda Tolkanova, Museum of Regional Studies of Labytnangi City
      </div>
      <div>
        Lyudmila Lipatova and the staff of I.S. Shemanovsky Yamal-Nenets
        District Museum and Exhibition Complex (Salekhard)
      </div>
      <div>Galina Zmeyeva, the Museum Center of Segezhi City</div>
      <div>
        Oksana Krasnova, the Memorial Museum of History and Regional Studies of
        Sosnogorsk City
      </div>
      <div>Mikhail Shibisty, Susuman People’s Museum</div>
      <div>
        Svetlana Bandura and Yelena Morozova, National Museum of the Komi
        Republic
      </div>
      <div>
        Sholban Orzhak and Ottuk Irgit, the Museum of Political Repression in
        Tuva
      </div>
      <div>
        Ivan Panikarov, “Memory of Kolyma” Museum (the settlement of Yagodnoye)
      </div>
      <div>
        Ivan Igoshin, Museum of Lider Tourist Club (the Republic of Sakha)
      </div>
      <br />
      <div>
        The project was implemented using a grant of the President of the
        Russian Federation for civil society development provided by the
        Presidential Grants Foundation. Go to My GULAG project website (
        <Link
          href='https://mygulag.ru'
          rel='noopener noreferrer'
          target='_blank'
        >
          mygulag.ru
        </Link>
        ) to see memoirs of the people who went through camps and repression.
      </div>
    </Description>
  );
}
