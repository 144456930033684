import styled from 'styled-components';
import React from 'react';

const Number = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  font-variant-numeric: tabular-nums;

  color: rgba(255, 255, 255, 0.8);
`;

const Label = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
/* or 14px */

display: flex;
align-items: center;

color: #FFFFFF;

opacity: 0.5;
`;

const Slot = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
flex-grow: 1;
`;

export default function LargeSlot({ number, label }) {
  return (
    <Slot>
      <Number>{number}</Number>
      <Label>{label}</Label>
    </Slot>
  );
}
