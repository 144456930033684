import React from 'react';
import PropTypes from 'prop-types';
import content, { companies } from 'config/aboutProject';
import { t } from 'intl/helper';

// components
import FullScreenCard from '../FullScreenCard/FullScreenCard';
import Footer from './Footer';

// styled
import Description from './styled/Description';
import SubTitle from './styled/SubTitle';
import CompaniesContainer from './styled/CompaniesContainer';
import Company from './styled/Company';
import CompanyName from './styled/CompanyName';
import Position from './styled/Position';
import Name from './styled/Name';
import MailTo from './styled/MailTo';
import AcknowledgementsRu from './Acknowledgements/AcknowledgementsRu';
import AcknowledgementsEn from './Acknowledgements/AcknowledgementsEn';

const About = ({ locale, closeCard }) => (
  <FullScreenCard onClick={closeCard} title={t('aboutCard.heading')}>
    <Description>
      {content.map(({ id, paragraph }) => (
        <p key={id}>{paragraph[locale]}</p>
      ))}
    </Description>
    <SubTitle>{t('aboutCard.team')}</SubTitle>
    <CompaniesContainer>
      {companies.map(({ name, people }) => (
        <>
          <Company>
            <CompanyName>{name[locale]}</CompanyName>
            {people[locale].map(({ name: personName, position }) => (
              <>
                {position && <Position>{position}</Position>}
                <Name>{personName}</Name>
              </>
            ))}
          </Company>
        </>
      ))}
    </CompaniesContainer>
    {locale === 'ru' ? <AcknowledgementsRu /> : <AcknowledgementsEn />}
    <MailTo href='mailto:gulagmap@gmig.ru'>gulagmap@gmig.ru</MailTo>
    <Footer locale={locale} />
  </FullScreenCard>
);

About.propTypes = {
  locale: PropTypes.PropTypes.oneOf(['ru', 'en', 'de']).isRequired,
  closeCard: PropTypes.func.isRequired
};

export default About;
