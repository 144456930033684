import React from 'react';
import PropTypes, { object } from 'prop-types';
import { t } from 'intl/helper';
import { splitDigits } from 'utils/utils';

// styled
import styled from 'styled-components';
import Container from './Container';
import LargeSlot from './LargeSlot';
import SlotWithLine from './SlotWithLine';
import Flex from './Flex';
import Button from './Button';
import Data from './Data';
import {
  BottomBar,
  BottomSubBar,
  BottomBarLink,
  BottomBarLinkA
} from './BottomBar';
import campsColors from '../../../config/campsColors';

const GraphIcon = () => (
  <svg
    width='50'
    height='22'
    viewBox='0 0 50 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      width='2'
      height='1'
      transform='translate(0 21)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='2'
      transform='translate(4 20)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='4'
      transform='translate(8 18)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='6'
      transform='translate(12 16)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='9'
      transform='translate(16 13)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='13'
      transform='translate(20 9)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='18'
      transform='translate(24 4)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='13'
      transform='translate(28 9)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='9'
      transform='translate(32 13)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='16'
      transform='translate(36 6)'
      fill='currentColor'
    />
    <rect width='2' height='22' transform='translate(40)' fill='currentColor' />
    <rect
      width='2'
      height='11'
      transform='translate(44 11)'
      fill='currentColor'
    />
    <rect
      width='2'
      height='5'
      transform='translate(48 17)'
      fill='currentColor'
    />
  </svg>
);

const Label = styled.span`
  font-family: Formular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  opacity: 0.5;
`;

const ChartStat = ({
  currentYear,
  isShowAll,
  toggleAllPrisons,
  showData,
  prisonersTotal,
  deadTotal,
  chartData,
  campTypeFilters,
  pushToChronology,
  pushToAbout,
  lang,
  span
}) => {
  const { prisoners, dead } =
    chartData.find(({ year }) => year === currentYear) || {};
  const slitedPrisoners = isShowAll
    ? splitDigits(prisonersTotal)
    : splitDigits(prisoners);
  const slitedDead = isShowAll ? splitDigits(deadTotal) : splitDigits(dead);

  return (
    <Flex right={campTypeFilters === '7'}>
      <Container isShowAll={isShowAll}>
        <LargeSlot
          number={isShowAll ? span : currentYear}
          label={t('year')}
        />
        {showData && (
          <Data>
            {!isShowAll && (
              <>
                <SlotWithLine
                  color={`rgb(${campsColors[campTypeFilters]})`}
                  number={
                    prisoners > 0 || (isShowAll && prisonersTotal > 0)
                      ? slitedPrisoners
                      : t('noData')
                  }
                  label={t('prisoners')}
                />
                <SlotWithLine
                  color='#E2F3E3'
                  number={
                    dead > 0 || (isShowAll && deadTotal > 0)
                      ? slitedDead
                      : t('noData')
                  }
                  label={t('dead')}
                />
              </>
            )}
          </Data>
        )}
      </Container>
      <Button isActive={isShowAll} onClick={toggleAllPrisons}>
        <GraphIcon />
        <Label>{t('showAllButton')}</Label>
      </Button>
      <BottomBar>
        <BottomSubBar>
          <BottomBarLink onClick={() => pushToAbout()}>
            {t('aboutCard.heading')}
          </BottomBarLink>
          <BottomBarLink onClick={() => pushToChronology()}>
            {t('aside.gulagChronology')}
          </BottomBarLink>
        </BottomSubBar>
        <BottomSubBar>
          <BottomBarLinkA active={lang === 'ru'} href='https://gulagmap.ru'>
            Rus
          </BottomBarLinkA>
          <BottomBarLinkA active={lang === 'en'} href='https://gulagmap.org'>
            Eng
          </BottomBarLinkA>
        </BottomSubBar>
      </BottomBar>
    </Flex>
  );
};

ChartStat.propTypes = {
  currentYear: PropTypes.number.isRequired,
  isShowAll: PropTypes.bool.isRequired,
  toggleAllPrisons: PropTypes.func.isRequired,
  showData: PropTypes.bool.isRequired,
  chartData: PropTypes.arrayOf(object).isRequired,
  prisonersTotal: PropTypes.number.isRequired,
  deadTotal: PropTypes.number.isRequired,
  campTypeFilters: PropTypes.string.isRequired,
  pushToChronology: PropTypes.func.isRequired,
  pushToAbout: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired
};

export default ChartStat;
