import styled from 'styled-components';

export default styled.div`
  margin-bottom: 20px;

  font-size: 17px;
  font-weight: 900;

  color: #e2f3e3;
`;
