import styled from 'styled-components';

export default styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  /* or 13px */

  display: flex;
  align-items: center;

  color: #cfcfd0;
`;
