import styled from 'styled-components';

export default styled.div`
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */

display: flex;
align-items: center;

color: #CFCFD0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  transition: 0.4s;
`;
