import { fromJS } from 'immutable';
import createImmutableSelector from 'create-immutable-selector';
import { UPDATE } from 'react-intl-redux';
import ruLocale from 'intl/ru';
import enLocale from 'intl/en';

const initialState = fromJS(
  window.location.hostname === 'gulagmap.org' ? enLocale : ruLocale
);
const intlSelector = createImmutableSelector(
  state => state.get('intl'),
  intl => intl
);

export const localeSelector = createImmutableSelector(intlSelector, intl =>
  intl.get('locale'));

export default (state = initialState, action) => {
  if (action.type !== UPDATE) {
    return state;
  }

  return state.merge(action.payload);
};
