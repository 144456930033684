import transition from 'styled-transition-group';

export default transition.div`
  position: fixed;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  gap: 4px;
  left: 20px;
  top: 20px;

  &:enter {
    top: -100px;
  }

  &:enter-active {
    top: 28px;
    transition: top 400ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
`;
