import styled from 'styled-components';

export default styled.button`
  all: unset;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 46px;
  height: 46px;

  background: #171b20;
  border-radius: 16px;

  @media (max-width: 768px) {
    display: none;
  }
`;
