import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  gap: 2px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex};
  gap: 5px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(217, 217, 217, 0.2);
`;

const Label = styled.p`
  margin: 0;
  font-size: 12px;
  color: rgba(226, 243, 227, 0.6);
  width: 70%;
`;

function Period({ flex, name }) {
  return (
    <PeriodContainer flex={flex}>
      <Line />
      <Label>{name}</Label>
    </PeriodContainer>
  );
}

export default function Chronology({ periods, lang }) {
  return (
    <Container>
      {periods.toJS().map((period, index) => (
        <Period
          flex={
            (periods.toJS().length > index + 1
              ? periods.toJS()[index + 1].year
              : 1960) - period.year
          }
          name={period.title[lang]}
        />
      ))}
    </Container>
  );
}
