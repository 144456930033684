import styled from 'styled-components';

export default styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  width: 100px;
  height: 68px;

  background: ${({ isActive }) => (isActive ? '#f6f6f7' : '#171b20')};
  border-radius: 16px;

  color: ${({ isActive }) => (isActive ? '#000' : '#fff')};

  &:hover {
    background: ${({ isActive }) =>
      isActive ? 'rgba(246, 246, 247, 0.5)' : 'rgba(23, 27, 32, 0.5)'};
  }

  &:active {
    background: #f6f6f7;
    color: #171b20;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;