import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  gap: ${({isShowAll}) => isShowAll ? '0px' : '20px'};
  background: #171b20;
  border-radius: 16px;
  flex-grow: 0;
  align-self: stretch;

  @media (max-width: 768px) {
    flex-grow: 1;
  }
`;