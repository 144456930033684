import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { t } from 'intl/helper';

// styled
import Container from './Container';
import Title from './Title';
import LinkButton from './LinkButton';
import Flex from './Flex';
import SearchButton from './SearchButton';

const SearchIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      opacity='0.8'
      // eslint-disable-next-line max-len
      d='M0.437499 8.01029C0.437499 9.07974 0.638888 10.0832 1.04167 11.0207C1.44444 11.9582 2 12.7846 2.70833 13.4999C3.42361 14.2152 4.25 14.7742 5.1875 15.177C6.125 15.5797 7.12847 15.7811 8.19792 15.7811C9.06597 15.7811 9.88542 15.6492 10.6562 15.3853C11.4271 15.1145 12.1354 14.7429 12.7812 14.2707L17.7396 19.2499C17.8437 19.3471 17.9618 19.42 18.0937 19.4686C18.2326 19.5242 18.3715 19.552 18.5104 19.552C18.7187 19.552 18.9028 19.5034 19.0625 19.4061C19.2222 19.3159 19.3472 19.1874 19.4375 19.0207C19.5278 18.861 19.5729 18.677 19.5729 18.4686C19.5729 18.3297 19.5451 18.1943 19.4896 18.0624C19.441 17.9374 19.3681 17.8228 19.2708 17.7186L14.3333 12.7603C14.8472 12.1075 15.25 11.3783 15.5417 10.5728C15.8333 9.76029 15.9792 8.90613 15.9792 8.01029C15.9792 6.93391 15.7778 5.92696 15.375 4.98946C14.9722 4.05196 14.4132 3.22904 13.6979 2.52071C12.9826 1.80543 12.1562 1.24641 11.2187 0.843627C10.2812 0.433905 9.27431 0.229044 8.19792 0.229044C7.12847 0.229044 6.125 0.433905 5.1875 0.843627C4.25 1.24641 3.42361 1.80543 2.70833 2.52071C2 3.22904 1.44444 4.05196 1.04167 4.98946C0.638888 5.92696 0.437499 6.93391 0.437499 8.01029ZM1.94792 8.01029C1.94792 7.14224 2.11111 6.33321 2.4375 5.58321C2.76389 4.82627 3.21181 4.16307 3.78125 3.59363C4.35764 3.01724 5.02083 2.56932 5.77083 2.24988C6.52778 1.92349 7.33681 1.76029 8.19792 1.76029C9.06597 1.76029 9.875 1.92349 10.625 2.24988C11.3819 2.56932 12.0451 3.01724 12.6146 3.59363C13.191 4.16307 13.6389 4.82627 13.9583 5.58321C14.2847 6.33321 14.4479 7.14224 14.4479 8.01029C14.4479 8.87141 14.2847 9.68043 13.9583 10.4374C13.6389 11.1874 13.191 11.8506 12.6146 12.427C12.0451 12.9964 11.3819 13.4443 10.625 13.7707C9.875 14.0902 9.06597 14.2499 8.19792 14.2499C7.33681 14.2499 6.52778 14.0902 5.77083 13.7707C5.02083 13.4443 4.35764 12.9964 3.78125 12.427C3.21181 11.8506 2.76389 11.1874 2.4375 10.4374C2.11111 9.68043 1.94792 8.87141 1.94792 8.01029Z'
      fill='white'
    />
  </svg>
);

const Menu = ({ dispatch, isDataLoading }) => (
  <Flex>
    <Container in={!isDataLoading} timeout={400}>
      <Title>{t('menu.title')}</Title>

      <LinkButton onClick={() => dispatch(push('/chronology'))}>
        {t('aside.gulagChronology')}
      </LinkButton>

      <LinkButton onClick={() => dispatch(push('/about'))}>
        {t('aboutCard.heading')}
      </LinkButton>
    </Container>

    <SearchButton onClick={() => dispatch(push('/search'))}>
      <SearchIcon />
    </SearchButton>
  </Flex>
);

Menu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired
};

export default connect(state => ({
  isDataLoading: state.getIn(['ui', 'isDataLoading']),
  lang: state.getIn(['intl', 'locale'])
}))(Menu);
