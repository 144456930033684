import createImmutableSelector from 'create-immutable-selector';
import { connect } from 'react-redux';

// selectors
import { push } from 'connected-react-router';
import { typesSelector } from '../App/reducers/dataReducer';
import {
  isCampFiltersOpenSelector,
  campTypeFiltersSelector
} from '../App/reducers/uiReducer';
import { localeSelector } from '../App/reducers/intlReducer';

// actions
import { toggleCampFilters } from '../App/reducers/uiActions';

import Panel from './Panel';

const mapStateToProps = createImmutableSelector(
  typesSelector,
  isCampFiltersOpenSelector,
  campTypeFiltersSelector,
  typesSelector,
  localeSelector,
  (types, isCampFiltersOpen, campTypeFilters, typesInfo, locale) => ({
    types,
    isCampFiltersOpen,
    campTypeFilters,
    typesInfo,
    locale
  })
);
const mapDispatchToProps = dispatch => ({
  toggleCampFilters: () => dispatch(toggleCampFilters()),
  openSearch: () => dispatch(push('/search'))
});

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
