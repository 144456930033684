import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  bottom: 30px;
  gap: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Link = styled.a`
  text-decoration: none;
  font-size: 14px;
  color: ${({ active }) =>
    active ? 'rgba(207, 207, 208, 1)' : 'rgba(207, 207, 208, 0.5)'};
`;

export default function LangSwitch({lang}) {
  return (
    <Container>
      <Link href='https://gulagmap.ru' active={lang === 'ru'}>
        Rus
      </Link>
      <Link href='https://gulagmap.org' active={lang === 'en'}>
        Eng
      </Link>
    </Container>
  );
}