import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const Circle = () => (
  <svg
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='6.5' cy='6.5' r='6' fill='#171B20' stroke='#CFCFD0' />
  </svg>
);

const Line = styled.div`
  width: 1px;

  background: #d9d9d9;
  opacity: 0.4;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;

export default function Timeline({hideLine}) {
  return (
    <Container>
      <Circle />
      {!hideLine && <Line />}
    </Container>
  );
}
