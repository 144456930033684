/* eslint-disable no-else-return, react/destructuring-assignment */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { height, margin } from './config';

// components
import ChartStat from './ChartStat/ChartStat';
import PrisonersArea from './PrisonersArea/PrisonersArea';
import Slider from './Slider';

// styled
import Container from './styled/Container';
import ChartWrap from './styled/ChartWrap';
import getData from './data/adapter';
import ScrollWrap from './PrisonersArea/ScrollWrap';
import Chronology from './Chronology';
import LangSwitch from './LangSwitch';

const calculateChartWidth = () => {
  return window.innerWidth < 768 ? 1000 : window.innerWidth - 40;
};

class Chart extends PureComponent {
  static propTypes = {
    currentYear: PropTypes.number.isRequired,
    isDemoPlay: PropTypes.bool.isRequired,
    isShowAll: PropTypes.bool.isRequired,
    toggleAllPrisons: PropTypes.func.isRequired,
    changeCurrentYear: PropTypes.func.isRequired,
    toggleDemo: PropTypes.func.isRequired,
    isDataLoading: PropTypes.bool.isRequired,
    isChartVisible: PropTypes.bool.isRequired,
    campTypeFilters: PropTypes.string.isRequired,
    isCampFiltersOpen: PropTypes.bool.isRequired,
    pushToChronology: PropTypes.func.isRequired,
    pushToAbout: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    periods: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
  }

  state = {
    width: calculateChartWidth()
  };

  componentDidMount() {
    window.onresize = this.onResize;
    // this.scrollChartTo(1937);
  }

  scrollChartTo = (year) => {
    this.scrollRef.current.scrollTo({
      left: (year - 1918) * 23 + 10,
      behavior: 'smooth'
    })
    this.props.changeCurrentYear(year);
  }

  onResize = () => {
    this.setState({ width: calculateChartWidth() });
  };

  demo = () => {
    this.props.toggleDemo();
    if (this.props.isDemoPlay) {
      clearInterval(this.playDemo);
    } else {
      this.playDemo = setInterval(() => {
        if (this.props.currentYear < 1960 && this.props.isDemoPlay) {
          this.props.changeCurrentYear(this.props.currentYear + 1);
        } else if (this.props.currentYear === 1960) {
          clearInterval(this.playDemo);
          this.props.toggleDemo();
        } else {
          clearInterval(this.playDemo);
        }
      }, 1000);
    }
  };

  render() {
    
    const { width } = this.state;
    const {
      isDataLoading,
      currentYear,
      isShowAll,
      isChartVisible,
      toggleAllPrisons,
      campTypeFilters,
      isCampFiltersOpen,
      lang,
      changeCurrentYear,
      periods
    } = this.props;

    const { calculateXScale, chartData, yScale, prisoners, dead, span } =
      getData(campTypeFilters);

    return (
      <Container in={!isDataLoading} timeout={800} isOpen={!isCampFiltersOpen}>
        <ChartWrap>
          <ChartStat
            currentYear={currentYear}
            isShowAll={isShowAll}
            toggleAllPrisons={toggleAllPrisons}
            showData={isChartVisible}
            prisonersTotal={prisoners}
            deadTotal={dead}
            chartData={chartData}
            campTypeFilters={campTypeFilters}
            pushToChronology={this.props.pushToChronology}
            pushToAbout={this.props.pushToAbout}
            lang={lang}
            span={span}
          />
          <ScrollWrap
            ref={this.scrollRef}
            onScroll={(even) => {
              const calculatedYear =
                1918 + Math.round(even.target.scrollLeft / 23);
              if (calculatedYear > 1960) {
                changeCurrentYear(1960);
              } else if (calculatedYear < 1918) {
                changeCurrentYear(1918);
              } else {
                changeCurrentYear(calculatedYear);
              }
            }}
          >
            <svg
              width={width}
              height={
                height +
                margin.top +
                margin.bottom -
                (window.innerWidth < 768 ? 0 : 40) + 10
              }
            >
              <defs>
                <linearGradient
                  id='Gradient'
                  x1='0%'
                  y1='31%'
                  x2='10%'
                  y2='0%'
                  spreadMethod='repeat'
                >
                  <stop offset='0%' stopColor='rgb(225,225,225)' />
                  <stop offset='12%' stopColor='rgb(225,225,225)' />
                  <stop offset='13%' stopColor='rgb(0,0,0)' />
                  <stop offset='25%' stopColor='rgb(0,0,0)' />
                  <stop offset='26%' stopColor='rgb(225,225,225)' />
                  <stop offset='38%' stopColor='rgb(225,225,225)' />
                  <stop offset='39%' stopColor='rgb(0,0,0)' />
                  <stop offset='51%' stopColor='rgb(0,0,0)' />
                  <stop offset='52%' stopColor='rgb(225,225,225)' />
                  <stop offset='64%' stopColor='rgb(225,225,225)' />
                  <stop offset='65%' stopColor='rgb(0,0,0)' />
                  <stop offset='77%' stopColor='rgb(0,0,0)' />
                  <stop offset='78%' stopColor='rgb(225,225,225)' />
                  <stop offset='90%' stopColor='rgb(225,225,225)' />
                  <stop offset='91%' stopColor='rgb(0,0,0)' />
                  <stop offset='100%' stopColor='rgb(0,0,0)' />
                </linearGradient>
                <filter
                  id='gaussianBlur'
                  width='185.7%'
                  height='400%'
                  x='-42.9%'
                  y='-150%'
                  filterUnits='objectBoundingBox'
                >
                  <feGaussianBlur stdDeviation='5' in='SourceGraphic' />
                </filter>
                <filter id='textShadow'>
                  <feGaussianBlur in='SourceGraphic' stdDeviation='4' />
                </filter>
              </defs>
              {isChartVisible && (
                <PrisonersArea
                  width={width}
                  xScale={calculateXScale(width)}
                  isShowAll={isShowAll}
                  changeCurrentYear={changeCurrentYear}
                  chartData={chartData}
                  yScale={yScale}
                  scrollChartTo={this.scrollChartTo}
                  campTypeFilters={campTypeFilters}
                />
              )}
              {/* <Axis
              width={width}
              isChartVisible={isChartVisible}
              calculateXScale={calculateXScale}
            /> */}
              <Slider
                width={width}
                xScale={calculateXScale(width)}
                isChartVisible={isChartVisible}
                chartData={chartData}
                yScale={yScale}
              />
            </svg>
          </ScrollWrap>
          <Chronology periods={periods} lang={lang} />
        </ChartWrap>
        <LangSwitch lang={lang} />
      </Container>
    );
  }
}

export default Chart;
