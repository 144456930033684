export default [
  'ussr-boundary-v1-0-4-c6qmj8',
  /**
   * GDR layers
   * */
  'gdr-name',
  'gdr-boundary-24092019',
  'gdr-boundary-background',
  'berlin-boundary-24092019',
  'berlin-border-stroke',
  'cities-v1-0-1-gdr-cities',
  'cities-dots-v1-0-1-2-gdr'
];
