/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getPeriods } from 'utils/utils';

import FullScreenCard from 'components/FullScreenCard/FullScreenCard';

// styled
import Input from './Input';
import Item from './Item';
import Title from './Title';
import Periods from './Periods';
import Locations from './Locations';
import Detail from './Detail';
import campsColors from '../../config/campsColors';

const placeholder = {
  ru: 'Название лагеря...',
  en: 'Camp name...'
};

const title = {
  ru: "Поиск",
  en: "Search",
};

const GlowingThingy = ({color}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Ellipse 1">
      <circle cx="9" cy="9" r="4" fill={color} />
      <circle
        cx="9"
        cy="9"
        r="6.5"
        stroke={color}
        stroke-opacity="0.2"
        stroke-width="5"
      />
    </g>
  </svg>
);

class Search extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: ''
    };

    this.inputRef = React.createRef();

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  onSearchChange(e) {
    const searchQuery = e.target.value;

    this.setState({ searchQuery });
  }

  render() {
    const { searchQuery } = this.state;
    const { closeCard, camps, openCampCard, lang, regions } = this.props;

    const search = searchQuery.trim().toLowerCase();
    const campsFilteredBySearch = camps.filter(
      camp =>
        camp
          .getIn(['title', lang])
          .toLowerCase()
          .match(search) ||
        camp
          .getIn(['subTitles', lang])
          .toLowerCase()
          .match(search) ||
        camp
          .getIn(['description', lang])
          .toLowerCase()
          .match(search)
    );

    return (
      <FullScreenCard onClick={closeCard} title={title[lang]} width={600}>
        <Input
          ref={this.inputRef}
          placeholder={placeholder[lang]}
          onChange={this.onSearchChange}
        />
        {campsFilteredBySearch.map(camp => (
          <Item
            key={camp.get("id")}
            onClick={openCampCard.bind(null, camp.get("id"))}
          >
            <GlowingThingy color={`rgb(${campsColors[camp.get("typeId")]})`} />
            <Detail>
              <Title>{camp.getIn(["title", lang])}</Title>
              <Periods>{getPeriods(camp.get("locations"))}</Periods>
              <Locations>
                {regions.getIn([camp.get("regionId"), "title", lang])}
              </Locations>
            </Detail>
          </Item>
        ))}
      </FullScreenCard>
    );
  }
}

Search.propTypes = {
  closeCard: PropTypes.func.isRequired,
  camps: PropTypes.object.isRequired,
  openCampCard: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  regions: PropTypes.object.isRequired
};

export default Search;
