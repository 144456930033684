import createImmutableSelector from 'create-immutable-selector';
import { connect } from 'react-redux';

// selectors
import { push } from 'connected-react-router';
import {
  campTypeFiltersSelector,
  currentYearSelector,
  isCampFiltersOpenSelector,
  isDataLoadingSelector,
  isDemoPlaySelector,
  isShowAllPrisonsSelector
} from '../App/reducers/uiReducer';
import { localeSelector } from '../App/reducers/intlReducer';
import { periodsSelector } from '../App/reducers/dataReducer';

// actions
import {
  toggleAllPrisons,
  changeCurrentYear,
  toggleDemo
} from '../App/reducers/uiActions';

import Chart from './Chart';
import getData from './data/adapter';

const mapStateToProps = createImmutableSelector(
  campTypeFiltersSelector,
  isDataLoadingSelector,
  currentYearSelector,
  isDemoPlaySelector,
  isShowAllPrisonsSelector,
  localeSelector,
  isCampFiltersOpenSelector,
  periodsSelector,
  (
    campTypeFilters,
    isDataLoading,
    currentYear,
    isDemoPlay,
    isShowAllPrisons,
    locale,
    isCampFiltersOpen,
    periods
  ) => {
    const isChartVisible = Boolean(
      getData(campTypeFilters).chartData.length && !isDataLoading
    );

    return {
      currentYear,
      isDemoPlay,
      isShowAll: isShowAllPrisons,
      isDataLoading,
      isChartVisible,
      campTypeFilters,
      lang: locale,
      isCampFiltersOpen,
      periods
    };
  }
);

const mapDispatchToProps = dispatch => ({
  toggleAllPrisons: () => dispatch(toggleAllPrisons()),
  changeCurrentYear: newYear => dispatch(changeCurrentYear(newYear)),
  toggleDemo: () => dispatch(toggleDemo()),
  pushToChronology: () => dispatch(push('/chronology')),
  pushToAbout: () => dispatch(push('/about'))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chart);
