/* eslint-disable react/prop-types  */
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      // render fallback UI
      return <div>Что то пошло не так :( перезагрузите страницу</div>;
    }

    return children;
  }
}

export default ErrorBoundary;
