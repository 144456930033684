import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;

  background: rgba(41, 46, 52, 0.5);
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    background: rgba(41, 46, 52, 0.7);
  }
`;
