import transition from 'styled-transition-group';

export default transition.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;

  background: #171B20;
  border-radius: 16px;
`;
