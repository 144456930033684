import styled from 'styled-components';

export default styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
gap: 10px;
width: 100%;
`;