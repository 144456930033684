import styled from 'styled-components';

export default styled.h1`
  font-family: "Formular";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 99% */

  display: flex;
  align-items: center;

  color: #cfcfd0;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  margin: 0px 0px;
`;
