import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  margin-bottom: 5px;
`;
