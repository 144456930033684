export default {
  locale: 'ru',
  messages: {
    'menu.title': 'Карта советских лагерей',
    showAllButton: 'Весь период',
    'prisonCard.yearsOfOperation': 'Годы существования',
    'prisonCard.location': 'Местоположение',
    'prisonCard.prisonersByYears': 'Количество заключенных по годам',
    'prisonCard.production': 'Тип деятельности',
    'prisonCard.photo': 'Фото и документы',
    'aboutCard.heading': 'О проекте',
    'aboutCard.team': 'Команда проекта',
    'aside.search': 'Поиск',
    'aside.campTypes': 'Типы лагерей на карте',
    'aside.gulagChronology': 'Хронология',
    'campFilters.title': 'Типы лагерей на карте',
    prisoners: 'заключённых',
    dead: 'умерших',
    noData: 'Н/Д',
    year: 'год',
    loading: 'Загрузка'
  }
};
