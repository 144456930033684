import React from 'react';
import PropTypes from 'prop-types';

import Container from './Container';
import Button from './Button';
import ChildrenContainer from './ChildrenContainer';
import Header from './Header';
import Title from '../Menu/Title';

const CloseIcon = () => (
  <svg
    id='svg-dngb_ballotx'
    xmlns='http://www.w3.org/2000/svg'
    height='30'
    viewBox='0 0 139 350'
  >
    <path
      // eslint-disable-next-line max-len
      d='M704 -248 1240 -782.2541 1776 -248 1932 -404 1396.2549 -938 1932 -1472 1776 -1628 1240 -1093.746 704 -1628 548 -1472 1083.7451 -938 548 -404Z'
      transform='translate(-54.8 281.6) scale(0.1)'
      fill='currentColor'
    />
  </svg>
);

const FullScreenCard = ({ onClick, children, title, width }) => (
  <Container width={width}>
    <Header>
      <Title>{title}</Title>
      <Button onClick={onClick}>
        <CloseIcon />
      </Button>
    </Header>
    <ChildrenContainer>
      {children}
    </ChildrenContainer>
  </Container>
);

FullScreenCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
};

export default FullScreenCard;
