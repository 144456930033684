import styled from 'styled-components';
import React from 'react';

const Line = styled.div`
width: 25px;
height: 3px;

/* main camp color */

background: ${({color}) => color};
`;

const WithGap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
`;

const Number = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  display: flex;
  align-items: center;

  font-variant-numeric: tabular-nums;

  min-width: 65px;

  color: rgba(255, 255, 255, 0.8);
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  display: flex;
  align-items: center;

  color: #ffffff;

  opacity: 0.5;
`;

const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export default function SlotWithLine({ number, label, color }) {
  return (
    <Slot>
      <WithGap>
        <Line color={color} />
        <Number>{number}</Number>
      </WithGap>

      <Label>{label}</Label>
    </Slot>
  );
}
