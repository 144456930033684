import { max } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { height, margin } from '../config';

import itl from './itl';
import osobye from './osobye';
import spetslag from './spetslag';
import pfl from './pfl';
import conclag from './conclag';
import {
  CONCENTRATION_CAMPS,
  FILTRATION_CAMPS,
  ITL,
  NKVD,
  SPEC_CAMPS
} from '../../../constants';

export default function getData(id) {
  const chartDatasets = {
    [ITL]: itl,
    [SPEC_CAMPS]: osobye,
    [NKVD]: spetslag,
    [FILTRATION_CAMPS]: pfl,
    [CONCENTRATION_CAMPS]: conclag
  };

  const spans = {
    [ITL]: '1923-1960',
    [SPEC_CAMPS]: '1948-1960',
    [NKVD]: '1945-1950',
    [FILTRATION_CAMPS]: '1942-1948',
    [CONCENTRATION_CAMPS]: '1918-1922'
  };

  const chartData = chartDatasets[id];

  if (!chartData) {
    return {
      chartData: [],
      calculateXScale: width =>
        scaleTime()
          .domain([new Date(1918, 0, 1), new Date(1960, 11, 31)])
          .range([0, width - margin.left - margin.right])
          .clamp(true),
      yScale: () => null
    };
  }

  return {
    chartData,
    span: spans[id],
    yScale: scaleLinear()
      .domain([0, max(chartData, d => d.prisoners)])
      .range([height, 0]),
    calculateXScale: width =>
      scaleTime()
        .domain([new Date(1918, 0, 1), new Date(1960, 11, 31)])
        .range([0, width - margin.left - margin.right])
        .clamp(true),
    prisoners: chartData.reduce((acc, obj) => {
      return acc + obj.prisoners;
    }, 0),
    dead: chartData.reduce((acc, obj) => {
      return acc + obj.dead;
    }, 0)
  };
}
