import styled from 'styled-components';
import { mainFontColour } from 'config/styles';

export default styled.div`
  margin-bottom: 25px;

  font-size: 12px;
  font-weight: 900;

  line-height: 1.56;

  color: ${mainFontColour};
  opacity: 0.8;
`;
