export default [
  { year: 1918, prisoners: 0, dead: 0 },
  { year: 1919, prisoners: 0, dead: 0 },
  { year: 1920, prisoners: 0, dead: 0 },
  { year: 1921, prisoners: 0, dead: 0 },
  { year: 1922, prisoners: 0, dead: 0 },
  { year: 1923, prisoners: 0, dead: 0 },
  { year: 1924, prisoners: 0, dead: 0 },
  { year: 1925, prisoners: 0, dead: 0 },
  { year: 1926, prisoners: 0, dead: 0 },
  { year: 1927, prisoners: 0, dead: 0 },
  { year: 1928, prisoners: 0, dead: 0 },
  { year: 1929, prisoners: 0, dead: 0 },
  { year: 1930, prisoners: 0, dead: 0 },
  { year: 1931, prisoners: 0, dead: 0 },
  { year: 1932, prisoners: 0, dead: 0 },
  { year: 1933, prisoners: 0, dead: 0 },
  { year: 1934, prisoners: 0, dead: 0 },
  { year: 1935, prisoners: 0, dead: 0 },
  { year: 1936, prisoners: 0, dead: 0 },
  { year: 1937, prisoners: 0, dead: 0 },
  { year: 1938, prisoners: 0, dead: 0 },
  { year: 1939, prisoners: 0, dead: 0 },
  { year: 1940, prisoners: 0, dead: 0 },
  { year: 1941, prisoners: 0, dead: 0 },
  { year: 1942, prisoners: 0, dead: 0 },
  { year: 1943, prisoners: 0, dead: 0 },
  { year: 1944, prisoners: 0, dead: 0 },
  { year: 1945, prisoners: 53849, dead: 6503 },
  { year: 1946, prisoners: 83533, dead: 12374 },
  { year: 1947, prisoners: 61324, dead: 17038 },
  { year: 1948, prisoners: 54791, dead: 5525 },
  { year: 1949, prisoners: 28492, dead: 1475 },
  { year: 1950, prisoners: 29632, dead: 0 },
  { year: 1951, prisoners: 0, dead: 0 },
  { year: 1952, prisoners: 0, dead: 0 },
  { year: 1953, prisoners: 0, dead: 0 },
  { year: 1954, prisoners: 0, dead: 0 },
  { year: 1955, prisoners: 0, dead: 0 },
  { year: 1956, prisoners: 0, dead: 0 },
  { year: 1957, prisoners: 0, dead: 0 },
  { year: 1958, prisoners: 0, dead: 0 },
  { year: 1959, prisoners: 0, dead: 0 },
  { year: 1960, prisoners: 0, dead: 0 }
];