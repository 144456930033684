import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { IntlProvider } from 'react-intl-redux';
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter
} from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';

// reducers
import dataReducer from './components/App/reducers/dataReducer';
import mapReducer from './components/Map/mapReducer';
import uiReducer from './components/App/reducers/uiReducer';
import intlReducer from './components/App/reducers/intlReducer';

//
import Saga from './components/App/saga';
import GlobalStyles from './components/GlobalStyles';
import App from './components/App/App';
import ErrorBoundary from './components/ErrorBoundary';
// import registerServiceWorker from './registerServiceWorker';

Sentry.init({
  dsn: 'https://ece765c2c9314268a75e53b27011cfb8@sentry.io/1354304'
});

const history = createBrowserHistory();

const reducer = combineReducers({
  data: dataReducer,
  map: mapReducer,
  ui: uiReducer,
  intl: intlReducer,
  router: connectRouter(history)
});

let middleware;
const sagaMiddleware = createSagaMiddleware();
const routersMiddleware = routerMiddleware(history);
if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger({
    collapsed: true,
    stateTransformer: state => state.toJS()
  });
  middleware = composeWithDevTools(applyMiddleware(
    sagaMiddleware,
    routersMiddleware,
    loggerMiddleware
  ));
} else {
  middleware = applyMiddleware(
    sagaMiddleware,
    routersMiddleware
  );
}

const store = createStore(reducer, middleware);

sagaMiddleware.run(Saga);

const intlSelector = state => state.get('intl').toJS();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <IntlProvider intlSelector={intlSelector}>
        <ConnectedRouter history={history}>
          <GlobalStyles />
          <App />
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
// registerServiceWorker();
