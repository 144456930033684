/* eslint-disable max-len */

// logos
import museum from './logos/museum.svg';
import museumEn from './logos/museum-en.svg';
import urbica from './logos/urbica.svg';
import pgrants from './logos/pgrants.svg';
import fund from './logos/fund.svg';

export default [
  {
    id: 1,
    paragraph: {
      ru: '«Интерактивная карта советских лагерей» – это визуальная репрезентация истории советской лагерной системы, которая существовала с 1918 по 1960 годы. Карта создана Музеем истории ГУЛАГа и представляет собой пополняющуюся базу данных по истории и географии лагерных структур, находящихся в разные годы в подчинении ВЧК-НКВД РСФСР-ГПУ-ОГПУ-НКВД СССР-МВД.',
      en: 'Interactive map of the GULAG visualizes the history of Soviet forced labor camps that operated in the USSR from 1918 to 1960. The map has been created by the GULAG History Museum as a permanently updated database of history and geography of prison camps supervised in different years by the ChK (Extraordinary Commission for Combating Counter-Revolution, Profiteering and Corruption), NKVD of the RSFSR (People’s Commissariat of International Affairs of the Russian Soviet Federated Socialist Republic), GPU (Chief Political Administration), OGPU (United Chief Political Administration), NKVD of the USSR (People’s Commissariat of International Affairs of the Union of Soviet Socialist Republics), MVD (Ministry of Internal Affairs).'
    }
  },
  {
    id: 2,
    paragraph: {
      ru: 'Научную основу проекта составляют архивные материалы, фотоальбомы лагерей, мемуары и видеоинтервью очевидцев эпохи, а также фундаментальные научные исследования российских и зарубежных историков.',
      en: 'The project is based on the scientific analysis of archival materials, camp photograph albums, memoirs, video interviews with eye-witnesses of the time, as well as academic research by Russian and international historians.'
    }
  },
  {
    id: 3,
    paragraph: {
      ru: 'По карте можно проследить хронологию событий, увидеть, как зарождалась лагерная структура в Советской России, которая достигла концу 1940-х годов апогея в развитии, а в середине 1950-х годов была ликвидирована. Карта наглядно демонстрирует масштаб лагерной системы, которая охватила всю страну – от Балтийского моря и Крыма до Чукотки и Сахалина.',
      en: 'Chronologically, the map demonstrates how labor camps emerged in Soviet Russia, reached their climax by the late 1940-s and dissolved in the middle of 1950-s. One can see the scale of the Soviet punitive system spread out across the entire country from the Baltic Sea and the Crimea to Chukotka and Sakhalin.'
    }
  },
  {
    id: 4,
    paragraph: {
      ru: 'На карте представлены концентрационные лагеря периода Гражданской войны, лагеря особого назначения ОГПУ, исправительно-трудовые, особые, проверочно-фильтрационные лагеря, а также спецлагеря на территории Германии и лагерная система Главного управления военнопленных и интернированных.',
      en: 'The map includes concentration camps of the Civil War, OGPU special designation camps, corrective labor camps, special purpose camps, screening and filtration camps, special purpose camps in Germany and prison camps of the Chief Administration for the Affairs of Prisoners-of-War and Civilian Internees.'
    }
  },
  {
    id: 5,
    paragraph: {
      ru: 'При нажатии на точку на карте открывается справка с географическими, историческими и экономическими сведениями о каждом объекте. Размеры точек меняются при прокрутке «шкалы времени» в зависимости от максимального количества заключенных, находившихся на тот момент в лагере.',
      en: 'Clicking on different dots on the map takes you to a pop-up window with geographical, historical and economic information about every location. The size of the dots changes when scrolling through the time scale to reflect the maximal number of prisoners in the camp on a given year.'
    }
  },
  {
    id: 6,
    paragraph: {
      ru: 'В дальнейшем на карте появится информация о детских колониях, спецпоселениях для ссыльных крестьян и представителей депортированных народов, а также о местах массовых захоронений жертв репрессий. Карта будет также пополняться фотографиями и документами.',
      en: 'In future, the map will feature information about colonies for children, special settlements for exiled peasants and ethnic deportees, as well as mass graves of the victims of political repressions. The map will also be further updated with photographs and documents.'
    }
  },
  {
    id: 7,
    paragraph: {
      ru: 'Музей истории ГУЛАГа приглашает к сотрудничеству историков из регионов России и принимает любые сведения о лагерях и их подразделениях. Интерес представляют картографические материалы, фотоальбомы ОГПУ-НКВД–МВД СССР, статистические сведения, материалы из региональных архивов и музеев, а также современные фотографии «следов лагерей», которые будут добавлены как справочная информация к интерактивной карте.',
      en: 'The GULAG History Museum invites historians from the regions of Russia to collaborate and share any information on the labor camps and their subdivisions including cartographic materials, photographic albums of OGPU-NKVD-MVD of the USSR, statistical data, regional museum and archival data, and contemporary pictures of the camp traces that can be added to the interactive map.'
    }
  }
];

export const companies = [
  {
    name: {
      ru: 'Музей истории ГУЛАГа',
      en: 'GULAG History Museum'
    },
    people: {
      ru: [
        { name: 'Илья Удовенко', position: 'Руководитель проекта' },
        {
          name: 'Илья Удовенко, Анастасия Емельянова, Артем Латышев, Сергей Ким',
          position: 'Авторы составители'
        },
        {
          name: 'Георгий Бугаков, Матвей Рахматуллин',
          position: 'Картографирование и разработка'
        },
        {
          name: 'Александр Калашников, Константин Коновалов',
          position: 'Дизайн'
        },
        {
          name: 'Роман Романов, Ирина Неустроева, Анна Стадинчук, Галина Иванова',
          position: 'Кураторы проекта'
        },
        {
          name: 'Ольга Кривовяз, Дмитрий Белановский',
          position: 'Переводчики'
        }
      ],
      en: [
        {
          name: 'Ilya Udovenko',
          position: 'Project Manager'
        },
        {
          name: 'Ilya Udovenko, Anastasia Emelyanova, Artem Latyshev, Sergey Kim',
          position: 'Authors'
        },
        {
          name: 'George Bougakov, Matvey Rakhmatullin',
          position: 'Cartography and engineering'
        },
        {
          name: 'Konstantin Konovalov, Sasha Kalashnikov',
          position: 'Design'
        },
        {
          name: 'Roman Romanov, Irina Neustroyeva, Anna Stadinchuk, Galina Ivanova',
          position: 'Curators'
        },
        {
          name: 'Olga Krivovyaz, Dmitry Belanovsky',
          position: 'Translators'
        }
        
      ]
    }
  },
  {
    name: {
      ru: 'Урбика',
      en: 'Urbica'
    },
    people: {
      ru: [
        { name: 'Анна Баринова' },
        { name: 'Андрей Бахвалов' },
        { name: 'Константин Гордеев' },
        { name: 'Татьяна Иванникова' },
        { name: 'Антон Ивченко' },
        { name: 'Александр Калашников' },
        { name: 'Андрей Кармацкий' },
        { name: 'Степан Кузьмин' },
        { name: 'Тая Лавриненко' },
        { name: 'Анастасия Потехина' },
        { name: 'Кристина Чахоян' }
      ],
      en: [
        { name: 'Anna Barinova' },
        { name: 'Andrey Bakhvalov' },
        { name: 'Konstantin Gordeyev' },
        { name: 'Tatyana Ivannikova' },
        { name: 'Anton Ivchenko' },
        { name: 'Aleksandr Kalashnikov' },
        { name: 'Andrey Karmatsky' },
        { name: 'Stepan Kuzmin' },
        { name: 'Taya Lavrinenko' },
        { name: 'Anastasia Potekhina' },
        { name: 'Kristina Chakhoyan' }
      ]
    }
  }
];

export const links = [
  {
    id: 'gmig',
    href: 'http://gmig.ru/',
    src: {
      ru: museum,
      en: museumEn
    },
    alt: 'gulag museum'
  },
  {
    id: 'urbica',
    href: 'https://urbica.io/',
    src: {
      ru: urbica,
      en: urbica
    },
    alt: 'urbica'
  },
  {
    id: 'pgrants',
    href: 'https://президентскиегранты.рф/',
    src: {
      ru: pgrants,
      en: pgrants
    },
    alt: 'president grants fund'
  },
  {
    id: 'memoryfund',
    href: 'http://memoryfund.ru/',
    src: {
      ru: fund,
      en: fund
    },
    alt: 'memory fund'
  }
];
