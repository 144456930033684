import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  top: 20%;
  gap: 4px;
  margin-left: 20px;
  margin-right: 20px;

  z-index: 999;

  ${({right}) => (right ? 'right: 0' : 'left: 0')}

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    top: unset;
    bottom: 40px;
    flex-direction: column;
  }
`;
