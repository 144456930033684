import styled from 'styled-components';

const BottomBar = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;

  border-radius: 12px;
  background: #171B20;



  @media (min-width: 768px) {
    display: none;
  }
`;

const BottomSubBar = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const BottomBarLink = styled.div`
  color: #cfcfd0;
  font-size: 12px;
  font-family: Formular;
  font-style: normal;
  font-weight: 400;
  line-height: 13.8px;
  opacity: ${({active}) => (active ? 1 : 0.6)};
  cursor: pointer;
`;

const BottomBarLinkA = styled.a`
  color: #cfcfd0;
  font-size: 12px;
  font-family: Formular;
  font-style: normal;
  font-weight: 400;
  line-height: 13.8px;
  opacity: ${({active}) => (active ? 1 : 0.6)};
  cursor: pointer;
  text-decoration: none;
`;

export { BottomSubBar, BottomBarLink, BottomBar, BottomBarLinkA };