export default {
  locale: 'en',
  messages: {
    'menu.title': 'Soviet Labor Camp Map',
    showAllButton: 'Full period',
    'prisonCard.yearsOfOperation': 'Years of operation',
    'prisonCard.location': 'Location',
    'prisonCard.prisonersByYears': 'Number of prisoners by years',
    'prisonCard.production': 'Production performance',
    'prisonCard.photo': 'Photo and documents',
    'aboutCard.heading': 'About',
    'aboutCard.team': 'Credits',
    'aside.search': 'Search',
    'aside.campTypes': 'Camp types',
    'aside.gulagChronology': 'Chronology',
    'campFilters.title': 'Camp types',
    prisoners: 'prisoners',
    dead: 'dead',
    noData: 'N/A',
    year: 'year',
    loading: 'Loading'
  }
};
