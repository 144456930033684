import styled from 'styled-components';
import { mainFontColour } from 'config/styles';

import { margin } from '../config';

export default styled.g`
  transform: translate(${margin.left}px, ${margin.top}px);
  pointer-events: auto;
  scroll-snap-align: center;

  & g:first-child {
    & rect {
      fill: ${mainFontColour};
      opacity: ${({ showAllYears }) => (showAllYears ? 1 : 0.1)};
      transition: opacity 2s;
      &:hover {
        cursor: pointer;
        opacity: 0.2;
        transition: opacity 0.2s;
      }
    }
    & line {
      stroke: ${mainFontColour};
      stroke-width: 2px;
    }
  }

  & g:nth-child(2) {
    & rect {
      cursor: pointer;
      fill-opacity: 0.1;
    }
  }

  & g:last-child {
    & rect {
      fill: rgb(${({ campColor }) => campColor});
      opacity: ${({ showAllYears }) => (showAllYears ? 0.6 : 0.1)};
      transition: opacity 2s;
      &:hover {
        cursor: pointer;
        opacity: 0.2;
        transition: opacity 0.2s;
      }
    }
    & line {
      stroke: rgb(${({ campColor }) => campColor});
      stroke-width: 2px;
    }
  }

  @media (max-width: 1023px) {
    &:hover {
      & rect {
        opacity: 0.1 !important;
      }
    }
  }

  & g text {
    opacity: 0.2;
    fill: #e2f3e3;
    color: #e2f3e3;
    font-size: 10px;
    text-align: center;
  }
`;
