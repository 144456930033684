import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, {
  LanguageControl, Popup as MapGLPopup, Source, Layer, MapContext, Filter
} from '@urbica/react-map-gl';
import mapConfig from 'config/map';
import layersToFilter from 'config/layersToFilter';
import initLayers from 'config/layers';

// component
import Popup from './Popup/Popup';

// styled
import Container from './Container';

import Controls from './Controls/Controls';
import MapButton from './Controls/MapButton';
import plus from './btn-plus.svg';
import minus from './btn-minus.svg';

const style = { width: '100%', height: '100%' };

class Map extends PureComponent {
  static propTypes = {
    isSlideUp: PropTypes.bool.isRequired,
    viewport: PropTypes.object.isRequired,
    campsSource: PropTypes.object.isRequired,
    changeViewport: PropTypes.func.isRequired,
    openCampCard: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    closeCampCard: PropTypes.func.isRequired,
    isShowAllPrisons: PropTypes.bool.isRequired,
    currentYear: PropTypes.number.isRequired
  };

  state = {
    layers: initLayers,
    cursorStyle: '',
    showPopup: false,
    popupFeatures: [],
    popupCoordinates: {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentYear, lang, campId, isShowAllPrisons } = nextProps;
    const citiesFilter = List([
      'all',
      ['==', 'year', isShowAllPrisons ? 1960 : currentYear]
    ]);
    const citiesNames = `{historical_name${lang === 'ru' ? '' : '_en'}}`;
    const activeCampHaloFilter = List([
      'all',
      ['==', 'campId', parseInt(campId, 10)]
    ]);
    const activeCampNameFilter = List([
      'all',
      ['==', 'campId', parseInt(campId, 10)]
    ]);

    const updatedLayers = prevState.layers
      .setIn(['cities', 'filter'], citiesFilter)
      .setIn(['citiesDots', 'filter'], citiesFilter)
      .setIn(['cities', 'layout', 'text-field'], citiesNames)
      .setIn(['campHalo_active', 'filter'], activeCampHaloFilter)
      .setIn(['campName_active', 'filter'], activeCampNameFilter);

    return { layers: updatedLayers };
  }

  onMapClick = (e) => {
    const { showPopup } = this.state;
    const { closeCampCard } = this.props;

    if (showPopup) {
      this.setState({ showPopup: false });
    }

    const features = this.map.queryRenderedFeatures(
      e.point,
      {
        layers: ['campsHalo']
      }
    );

    if (features.length === 0) {
      closeCampCard();
    }
  };

  onLayerHover = (e) => {
    const feature = e.features[0];
    if (!feature) return;

    const { layers } = this.state;

    const newLayers = layers
      .setIn(['campsHalo_hover', 'filter', 2], feature.properties.campId)
      .setIn(['campsNames', 'filter', 2], feature.properties.campId);

    this.setState({
      layers: newLayers,
      cursorStyle: 'pointer'
    });
  };

  onLayerLeave = () => {
    const { layers } = this.state;

    const newLayers = layers
      .setIn(['campsHalo_hover', 'filter', 2], '')
      .setIn(['campsNames', 'filter', 2], '');

    this.setState({
      layers: newLayers,
      cursorStyle: ''
    });
  };

  openCampCardHandler = (lngLat, campId) => {
    const { showPopup } = this.state;
    const { openCampCard } = this.props;

    if (showPopup) {
      this.setState({ showPopup: false });
    }

    openCampCard(lngLat, campId);
  };

  onLayerClick = (e) => {
    if (e.features.length > 1) {
      this.setState({
        showPopup: true,
        popupFeatures: e.features,
        popupCoordinates: {
          longitude: e.features[0].geometry.coordinates[0],
          latitude: e.features[0].geometry.coordinates[1]
        }
      });
    } else {
      this.openCampCardHandler(
        e.features[0].geometry.coordinates,
        e.features[0].properties.campId
      );
    }
  };

  zoomIn = () => {
    const { viewport, changeViewport } = this.props;
    const { zoom } = viewport;

    changeViewport({ zoom: zoom + 1 });
  };

  zoomOut = () => {
    const { viewport, changeViewport } = this.props;
    const { zoom } = viewport;

    changeViewport({ zoom: zoom - 1 });
  };

  renderPopup = () => {
    const { showPopup, popupFeatures, popupCoordinates } = this.state;

    if (!showPopup) {
      return null;
    }

    return (
      <MapGLPopup
        closeButton={false}
        closeOnClick={false}
        anchor='left'
        offset={40}
        {...popupCoordinates}
      >
        <Popup
          features={popupFeatures}
          onClick={this.openCampCardHandler}
        />
      </MapGLPopup>
    );
  };

  render() {
    const { layers, cursorStyle } = this.state;
    const {
      isSlideUp, viewport, changeViewport, lang, campsSource, currentYear
    } = this.props;
    const filter = [
      'all',
      ['<=', 'year_start', currentYear],
      ['>=', 'year_end', currentYear]
    ];

    return (
      <Container slideUp={isSlideUp}>
        <MapGL
          style={style}
          onViewportChange={changeViewport}
          cursorStyle={cursorStyle}
          onClick={this.onMapClick}
          {...mapConfig}
          {...viewport}
        >
          {layersToFilter.map(layer => (
            <Filter
              key={layer}
              layerId={layer}
              filter={filter}
            />
          ))}
          <MapContext.Consumer>
            {(map) => {
              if (map) {
                this.map = map;
                this.map.touchZoomRotate.disableRotation();
              }

              return null;
            }}
          </MapContext.Consumer>
          <LanguageControl
            language={lang}
            defaultLanguage={lang}
          />
          {this.renderPopup()}
          <Source
            id='camps'
            {...campsSource}
          />
          <Layer {...layers.get('cities').toJS()} />
          <Layer {...layers.get('citiesDots').toJS()} />
          <Layer {...layers.get('camps').toJS()} />
          <Layer
            {...layers.get('campsHalo').toJS()}
            onHover={this.onLayerHover}
            onLeave={this.onLayerLeave}
            onClick={this.onLayerClick}
          />
          <Layer {...layers.get('campsHalo_hover').toJS()} />
          <Layer {...layers.get('campsNames').toJS()} />
          <Layer {...layers.get('campHalo_active').toJS()} />
          <Layer {...layers.get('campName_active').toJS()} />
        </MapGL>
        <Controls>
          <MapButton onClick={this.zoomIn}>
            <img src={plus} alt='plus' />
          </MapButton>
          <MapButton onClick={this.zoomOut}>
            <img src={minus} alt='minus' />
          </MapButton>
        </Controls>
      </Container>
    );
  }
}

export default Map;
