import React from 'react';
import Description from '../styled/Description';
import Link from '../styled/Link';

export default function AcknowledgementsRu() {
  return (
    <Description>
      <div>
        Благодарим за помощь в поиске информации и предоставлении архивных
        документов и фотографий:
      </div>
      <div>Государственный архив Российской Федерации</div>
      <div>
        Международное историко-просветительское, благотворительное и
        правозащитное общество «Мемориал»
      </div>
      <div>
        Юрия Дмитриева, руководителя Карельского отделения отделения
        Международного «Мемориала»
      </div>
      <div>
        Виктора Паасо, Карельское республиканское общественное учреждение НИЦ
        «Мемориал»
      </div>
      <div>
        Михаила Рогачева, Коми республиканский благотворительный общественный
        фонд жертв политических репрессий «Покаяние»
      </div>
      <div>
        Алексея Захарченко, Самарский филиал Московского городского
        педагогического университета
      </div>
      <div>Светлану Березовскую, Музей города Северска</div>
      <div>Василия Ханевича, Мемориальный музей «Следственная тюрьма НКВД»</div>
      <div>Ирину Витман, Музей «Город на Севере России» (Воркута)</div>
      <div>Ирину Устин, Кемский краеведческий музей «Поморье»</div>
      <div>
        Надежду Толканову, Городской краеведческий музей города Лабытнанги
      </div>
      <div>
        Людмилу Липатову и коллектив Ямало-ненецкого окружного
        музейно-выставочного комплекса им. И.С.Шемановского (Салехард)
      </div>
      <div>Галину Змееву, Музейный центр города Сегежи</div>
      <div>
        Оксану Краснову, Историко-краеведческий мемориальный музей города
        Сосногорска
      </div>
      <div>Михаила Шибистого, Народный музей Сусумана</div>
      <div>
        Светлану Бандуру и Елену Морозову, Национальный музей Республики Коми
      </div>
      <div>
        Шолбана Оржака и Оттука Иргита, Музей истории политических репрессий в
        Туве
      </div>
      <div>Ивана Паникарова, Музей «Память Колымы» (поселок Ягодное)</div>
      <div>
        Ивана Игошина, Музей туристического клуба «Лидер» (Республика Саха)
      </div>
      <br />
      <div>
        Проект реализован с использованием гранта Президента Российской
        Федерации на развитие гражданского общества, предоставленного Фондом
        президентских грантов. Воспоминания людей, прошедших лагеря, и тех, кого
        коснулись репрессии, можно посмотреть на сайте проекта «Мой ГУЛАГ»:
        {' '}
        <Link
          href='https://mygulag.ru'
          rel='noopener noreferrer'
          target='_blank'
        >
          mygulag.ru
        </Link>
      </div>
    </Description>
  );
}
