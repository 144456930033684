/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import campColors from "config/campsColors";

// styled
import Container from "./Container";
import Filter from "./Filter";
import FilterTop from "./FilterTop";
import FilterTitle from "./FilterTitle";
import Description from "./Description";
import { CONCENTRATION_CAMPS, FILTRATION_CAMPS, ITL, NKVD, SPEC_CAMPS } from "../../constants";

const CampFilterBadge = ({ color }) => (
  <div
    style={{
      display: "flex",
      padding: "3px 0px",
    }}
  >
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={`rgb(${color})`} />
    </svg>
  </div>
);

const CampFilters = props => {
  const {
    types,
    isCampFiltersOpen,
    closeCampFilters,
    campTypeFilters,
    toggleFilter,
    locale,
    enableAllPrisons,
  } = props;

  return (
    <Container
      mountOnEnter
      isCampFiltersOpen={isCampFiltersOpen}
      in={isCampFiltersOpen}
      timeout={250}
    >
      {types.toArray().length > 0 &&
        [CONCENTRATION_CAMPS, ITL, FILTRATION_CAMPS, NKVD, SPEC_CAMPS].map(typeIdent => {
          const type = types
            .toArray()
            .find(tp => tp.get("id").toString() === typeIdent.toString());
          const typeId = typeIdent.toString();
          const isActive = campTypeFilters === typeId;

          return (
            type && (
              <Filter
                key={type.get("id")}
                onClick={() => {
                  toggleFilter(typeId);
                  if (window.innerWidth > 768) {
                    enableAllPrisons();
                  }
                  
                  closeCampFilters();
                }}
              >
                <CampFilterBadge color={campColors[typeId]} />
                <FilterTop>
                  <FilterTitle isActive={isActive}>
                    {type.getIn(["title", locale])}
                  </FilterTitle>
                  <Description isActive={isActive}>
                    {type.getIn(["description", locale])}
                  </Description>
                </FilterTop>
              </Filter>
            )
          );
        })}
    </Container>
  );
};

CampFilters.propTypes = {
  types: PropTypes.object.isRequired,
  isCampFiltersOpen: PropTypes.bool.isRequired,
  closeCampFilters: PropTypes.func.isRequired,
  campTypeFilters: PropTypes.string.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default CampFilters;
