import styled from 'styled-components';

export default styled.button`
  all: unset;
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 99% */

  display: flex;
  align-items: center;

  cursor: pointer;

  color: #cfcfd0;

  @media (max-width: 768px) {
    display: none;
  }
`;