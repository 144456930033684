import transition from 'styled-transition-group';

export default transition.div`
  position: fixed;
  opacity: ${({ isCampFiltersOpen }) => (isCampFiltersOpen ? 1 : 0)};
  pointer-events: ${
    ({ isCampFiltersOpen }) => (isCampFiltersOpen ? 'auto' : 'none')
  };

  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 10px;
gap: 5px;

z-index: 1000;

width: calc(100vw - 40px);
max-width: 388px;
max-height: 80vh;
right: 20px;
top: 72px;

background: #171B20;
box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 
  0px 42px 33px rgba(0, 0, 0, 0.05), 
  0px 22.3363px 17.869px rgba(0, 0, 0, 0.04), 
  0px 12.5px 10px rgba(0, 0, 0, 0.035), 
  0px 6.6px 5.3px rgba(0, 0, 0, 0.03), 
  0px 2.7px 2.2px rgba(0, 0, 0, 0.02);
border-radius: 16px;

overflow-y: scroll;

  transition: 0.4s;
`;
